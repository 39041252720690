$brown1: #3c3f40;
$grey1: #eee;
$grey2: #e9e9e9;
$grey3: #404848;
$black1: rgba(60, 63, 64, 0.5);
$black2: rgba(64, 72, 72, 0.1);
$black5: rgba(64, 72, 72, 0.6);

/* stylelint-disable */
@-webkit-keyframes fpFadeInDown {
  0% {
    transform: translate3d(0, -20px, 0);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fpFadeInDown {
  0% {
    transform: translate3d(0, -20px, 0);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes fpSlideLeft {
  0% {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes fpSlideLeft {
  0% {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(-100%, 0, 0);
  }
}

@-webkit-keyframes fpSlideLeftNew {
  0% {
    transform: translate3d(100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fpSlideLeftNew {
  0% {
    transform: translate3d(100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes fpSlideRight {
  0% {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes fpSlideRight {
  0% {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(100%, 0, 0);
  }
}

@-webkit-keyframes fpSlideRightNew {
  0% {
    transform: translate3d(-100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fpSlideRightNew {
  0% {
    transform: translate3d(-100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes fpFadeOut {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fpFadeOut {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@-webkit-keyframes fpFadeIn {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fpFadeIn {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.flatpickr-calendar {
  display: none;
  border-radius: 5px;

  touch-action: manipulation;
}

.flatpickr-calendar.multiMonth
  .flatpickr-days
  .dayContainer:nth-child(n + 1)
  .flatpickr-day.inRange:nth-child(7n + 7) {
  box-shadow: none !important;
}

.flatpickr-calendar.multiMonth
  .flatpickr-days
  .dayContainer:nth-child(n + 2)
  .flatpickr-day.inRange:nth-child(7n + 1) {
  box-shadow: none;
}

.flatpickr-calendar:after,
.flatpickr-calendar:before {
  border: solid transparent;
}

.flatpickr-months {
  display: flex;
}

.flatpickr-months .flatpickr-month {
  position: relative;
  user-select: none;
}

.flatpickr-months .flatpickr-next-month,
.flatpickr-months .flatpickr-prev-month {
  position: absolute;
  z-index: 3;

  color: $brown1;
  line-height: 16px;
  text-decoration: none;

  cursor: pointer;

  fill: $brown1;

  right: 65px;
  top: 27px;
  left: auto;

  width: 24px;
  height: 24px;

  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flatpickr-months .flatpickr-prev-month {
  right: 70px;
}

.flatpickr-months .flatpickr-next-month {
  right: 40px;
}

.flatpickr-months .flatpickr-next-month.flatpickr-disabled,
.flatpickr-months .flatpickr-prev-month.flatpickr-disabled {
  display: none;
}

.flatpickr-prev-month i,
.flatpickr-next-month i,
.flatpickr-months .flatpickr-prev-month i,
.flatpickr-months .flatpickr-next-month i {
  position: relative;
}

.flatpickr-months .flatpickr-next-month:hover,
.flatpickr-months .flatpickr-prev-month:hover {
  color: var(--palettes-blue-100);
}

.flatpickr-months .flatpickr-next-month svg,
.flatpickr-months .flatpickr-prev-month svg {
  width: 14px;
  height: 14px;
}

.flatpickr-months .flatpickr-next-month svg path,
.flatpickr-months .flatpickr-prev-month svg path,
.flatpickr-next-month svg path,
.flatpickr-prev-month svg path {
  transition: 0.2s;
}

.flatpickr-months .flatpickr-next-month:hover svg,
.flatpickr-months .flatpickr-prev-month:hover svg {
  fill: var(--palettes-blue-100);
}

.numInputWrapper input::-webkit-inner-spin-button,
.numInputWrapper input::-webkit-outer-spin-button {
  margin: 0;

  -webkit-appearance: none;
}

.numInputWrapper span:after {
  position: absolute;
  top: 33%;

  display: block;

  content: '';
}

.numInputWrapper span.arrowUp:after {
  top: 26%;
}

.numInputWrapper span.arrowDown:after {
  top: 40%;
}

.numInputWrapper span svg {
  width: inherit;
  height: auto;
}

.numInputWrapper span svg path {
  fill: $black1;
}

.flatpickr-current-month input.cur-year {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  position: relative;
  box-sizing: border-box;
  border: 0;
  border-radius: 0;
  background: 0 0;
  outline: 0;
  cursor: pointer;
  appearance: none;
  width: 100px;
}

.flatpickr-current-month {
  &:after {
    display: block;
    content: '';
    width: 16px;
    height: 16px;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.9125 5.99952L8.03251 9.87952L4.15251 5.99952C3.96568 5.81226 3.71203 5.70703 3.44751 5.70703C3.18299 5.70703 2.92934 5.81226 2.74251 5.99952C2.35251 6.38952 2.35251 7.01952 2.74251 7.40952L7.33251 11.9995C7.72251 12.3895 8.35251 12.3895 8.74251 11.9995L13.3325 7.40952C13.7225 7.01952 13.7225 6.38952 13.3325 5.99952C12.9425 5.61952 12.3025 5.60952 11.9125 5.99952V5.99952Z' fill='%2389888C'/%3E%3C/svg%3E%0A");
    position: absolute;
    top: 7px;
    left: 77px;
    pointer-events: none;
  }
}

.flatpickr-current-month .flatpickr-monthDropdown-months:active,
.flatpickr-current-month .flatpickr-monthDropdown-months:focus {
  outline: 0;
}

.flatpickr-current-month
  .flatpickr-monthDropdown-months
  .flatpickr-monthDropdown-month {
  padding: 0;

  background-color: transparent;
  outline: 0;
}

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  user-select: none;
  display: flex;
  flex: 1;
  height: 20px;
  gap: 12px;
  margin-bottom: 12px;
}

.flatpickr-days {
  align-items: flex-start;
}

.dayContainer + .dayContainer {
  box-shadow: -1px 0 0 $grey1;
}

.flatpickr-day.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day.nextMonthDay:focus,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.today.inRange,
.flatpickr-day:focus,
.flatpickr-day:hover {
  border-color: transparent;
}

.flatpickr-day.today:focus,
.flatpickr-day.today:hover {
  color: var(--palettes-white-100);

  background: var(--palettes-blue-100);
}

.flatpickr-day.endRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.endRange.nextMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.endRange:focus,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected,
.flatpickr-day.selected.inRange,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.selected:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.startRange:focus,
.flatpickr-day.startRange:hover {
  background: #4f99ff;
}

.flatpickr-day.inRange {
  border-radius: 2px;

  box-shadow: none;
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
  border-color: transparent;

  color: $black2;

  background: 0 0;
  cursor: not-allowed;
}

.flatpickr-day.hidden {
  visibility: hidden;
}

.flatpickr-time input {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.flatpickr-time .flatpickr-am-pm:focus,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time input:hover {
  background: #f1f1f1;
}

span.flatpickr-day,
span.flatpickr-day.nextMonthDay,
span.flatpickr-day.prevMonthDay {
  border: 1px solid $grey2;
}

@media (min-width: 0\0) and (-webkit-min-device-pixel-ratio: 0.75),
  (min-width: 0\0) and (min-resolution: 72dpi) {
  span.flatpickr-day {
    display: block;
    flex: 1 0 auto;
  }
}

.flatpickr-calendar {
  position: absolute;

  box-sizing: border-box;
  max-height: 0;
  padding: 0;
  border: 0;
  overflow: hidden;

  font-size: 14px;
  line-height: 24px;
  direction: ltr;
  text-align: center;

  background: 0 0;
  background: var(--palettes-white-100);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  visibility: hidden;
  opacity: 0;

  -webkit-animation: none;
  animation: none;
}

.flatpickr-calendar.inline,
.flatpickr-calendar.open {
  max-height: 640px;
  overflow: visible;

  border: 2px solid var(--palettes-gray-40);
  visibility: visible;
  opacity: 1;
}

.flatpickr-calendar.open {
  z-index: 10;

  display: inline-block;
}

.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-calendar.inline {
  position: relative;
  top: 2px;

  display: block;
}

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}

.flatpickr-calendar.static.open {
  z-index: 999;

  display: block;
}

.flatpickr-calendar.hasWeeks {
  width: auto;
}

.flatpickr-calendar .hasTime .dayContainer,
.flatpickr-calendar .hasWeeks .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}

.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid $grey1;
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}

.flatpickr-calendar.arrowTop:after,
.flatpickr-calendar.arrowTop:before {
  bottom: 100%;
}

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: $grey1;
}

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: var(--palettes-white-100);
}

.flatpickr-calendar.arrowBottom:after,
.flatpickr-calendar.arrowBottom:before {
  top: 100%;

  transform: rotateZ(180deg);
}

.flatpickr-calendar.arrowBottom:before {
  border-top-color: $grey1;
}

.flatpickr-calendar.arrowBottom:after {
  border-top-color: var(--palettes-white-100);
}

.flatpickr-calendar:focus,
.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}

.flatpickr-wrapper {
  display: inline-block;
}

.flatpickr-month {
  position: relative;
  width: 100%;
  overflow: hidden;

  line-height: 1;
  text-align: center;

  background: 0 0;

  user-select: none;
}

.flatpickr-next-month,
.flatpickr-prev-month {
  position: absolute;
  top: 0;
  z-index: 3;

  padding: 10px calc(3.57% - 1.5px);

  line-height: 16px;
  text-decoration: none;

  cursor: pointer;
}

.flatpickr-next-month:hover,
.flatpickr-prev-month:hover {
  color: var(--palettes-blue-100);
}

.flatpickr-next-month:hover svg,
.flatpickr-prev-month:hover svg {
  fill: var(--palettes-blue-100);
}

.flatpickr-next-month svg,
.flatpickr-prev-month svg {
  width: 14px;
}

.numInputWrapper {
  position: relative;

  height: auto;
}

.numInputWrapper input,
.numInputWrapper span {
  display: inline-block;
}

.numInputWrapper input {
  width: 100%;
}

.numInputWrapper input::-ms-clear {
  display: none;
}

.numInputWrapper span {
  position: absolute;
  right: 0;

  box-sizing: border-box;
  width: 14px;
  height: 50%;
  padding: 0 4px 0 2px;
  border: 1px solid rgba(64, 72, 72, 0.05);

  line-height: 50%;

  cursor: pointer;
  opacity: 0;
}

.numInputWrapper span:hover {
  background: rgba(0, 0, 0, 0.1);
}

.numInputWrapper span:active {
  background: rgba(0, 0, 0, 0.2);
}

.numInputWrapper span.arrowUp {
  top: 0;

  border-bottom: 0;
}

.numInputWrapper span.arrowUp:after {
  border-right: 4px solid transparent;
  border-bottom: 4px solid $black5;
  border-left: 4px solid transparent;
}

.numInputWrapper span.arrowDown {
  top: 50%;
}

.numInputWrapper span.arrowDown:after {
  border-top: 4px solid $black5;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.flatpickr-wrapper:hover:after,
.numInputWrapper:hover span {
  opacity: 1;
}

.flatpickr-current-month {
  display: inline-block;

  color: inherit;
  font-weight: 300;
  font-size: 135%;
  line-height: 1;
  text-align: center;
}

.flatpickr-current-month.slideLeft {
  transform: translate3d(-100%, 0, 0);

  -webkit-animation: fpFadeOut 400ms ease,
    fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeOut 400ms ease,
    fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-current-month.slideLeftNew {
  transform: translate3d(100%, 0, 0);

  -webkit-animation: fpFadeIn 400ms ease,
    fpSlideLeftNew 400ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeIn 400ms ease,
    fpSlideLeftNew 400ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-current-month.slideRight {
  transform: translate3d(100%, 0, 0);

  -webkit-animation: fpFadeOut 400ms ease,
    fpSlideRight 400ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeOut 400ms ease,
    fpSlideRight 400ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-current-month.slideRightNew {
  transform: translate3d(0, 0, 0);

  -webkit-animation: fpFadeIn 400ms ease,
    fpSlideRightNew 400ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeIn 400ms ease,
    fpSlideRightNew 400ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-current-month span.cur-month {
  display: inline-block;
  margin-left: 1.9ch;
  padding: 0;

  color: inherit;
  font-weight: 500;
  font-family: inherit;
}

.flatpickr-current-month .numInputWrapper {
  display: inline-block;
  width: 6ch;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: $brown1;
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: $brown1;
}

.flatpickr-current-month input.cur-year {
  display: inline-block;
  box-sizing: border-box;
  height: initial;
  margin: 0;
  padding: 0 0 0 0.5ch;
  border: 0;
  border-radius: 0;

  color: inherit;
  font-weight: 300;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  vertical-align: initial;

  background: 0 0;
  cursor: text;
}

.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  color: $black1;
  font-size: 100%;

  background: 0 0;

  pointer-events: none;
}

.flatpickr-weekdaycontainer,
.flatpickr-weekdays {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;

  text-align: center;

  background: 0 0;
}

span.flatpickr-weekday {
  display: block;
  flex: 1;
  margin: 0;

  color: var(--palettes-gray-80);
  font-weight: 400;

  font-size: 14px;
  line-height: 1;
  text-align: center;

  background: 0 0;
  cursor: default;
}

.flatpickr-weeks {
  padding: 1px 0 0;
}

.flatpickr-days {
  position: relative;

  display: flex;
  overflow: hidden;
}

.flatpickr-days:focus {
  outline: 0;
}

.dayContainer,
.flatpickr-day {
  display: inline-block;
  box-sizing: border-box;
}

.dayContainer {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-around;
  text-align: left;

  outline: 0;
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.flatpickr-calendar.animate .dayContainer.slideLeft {
  transform: translate3d(-100%, 0, 0);

  -webkit-animation: fpFadeOut 400ms cubic-bezier(0.23, 1, 0.32, 1),
    fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeOut 400ms cubic-bezier(0.23, 1, 0.32, 1),
    fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-calendar.animate .dayContainer.slideLeftNew {
  transform: translate3d(-100%, 0, 0);

  -webkit-animation: fpFadeIn 400ms cubic-bezier(0.23, 1, 0.32, 1),
    fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeIn 400ms cubic-bezier(0.23, 1, 0.32, 1),
    fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-calendar.animate .dayContainer.slideRight {
  transform: translate3d(100%, 0, 0);

  -webkit-animation: fpFadeOut 400ms cubic-bezier(0.23, 1, 0.32, 1),
    fpSlideRight 400ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeOut 400ms cubic-bezier(0.23, 1, 0.32, 1),
    fpSlideRight 400ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-calendar.animate .dayContainer.slideRightNew {
  -webkit-animation: fpFadeIn 400ms cubic-bezier(0.23, 1, 0.32, 1),
    fpSlideRightNew 400ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeIn 400ms cubic-bezier(0.23, 1, 0.32, 1),
    fpSlideRightNew 400ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-day {
  user-select: none;
  display: flex;
  align-items: center;
  position: relative;

  justify-content: center;
  width: 24px;

  height: 20px;
  line-height: 20px;
  margin: 0;
  border: 1px solid transparent;

  color: $grey3;
  font-weight: 400;
  text-align: center;

  background: 0 0;
  cursor: pointer;
  border-radius: 2px;
}

.flatpickr-day.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day.nextMonthDay:focus,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.today.inRange {
  background: var(--palettes-blue-20);
  outline: 0;
  cursor: pointer;
}

.flatpickr-day:focus,
.flatpickr-day:hover {
  background: $grey2;
}

.flatpickr-day.today {
  border-color: var(--palettes-gray-80);
}

.flatpickr-day.today:focus,
.flatpickr-day.today:hover {
  border-color: var(--palettes-blue-100);
}

.flatpickr-day.endRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.endRange.nextMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.endRange:focus,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected,
.flatpickr-day.selected.inRange,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.selected:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.startRange:focus,
.flatpickr-day.startRange:hover {
  border-color: var(--palettes-blue-100);

  color: var(--palettes-white-100);

  background: var(--palettes-blue-100);
  box-shadow: none;
}

.flatpickr-day.endRange.endRange,
.flatpickr-day.endRange.startRange,
.flatpickr-day.selected.endRange,
.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.startRange.startRange {
  border-color: var(--palettes-blue-100);
}

.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange {
  border-radius: 2px;
}

.flatpickr-day.disabled,
.flatpickr-day.disabled:hover {
  border-color: var(--palettes-white-100);

  background: 0 0;
}

.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.nextMonthDay,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.prevMonthDay {
  border-color: var(--palettes-white-100);

  color: var(--palettes-gray-80);

  background: 0 0;
  cursor: default;
}

.flatpickr-day.disabled,
.flatpickr-day.disabled:hover {
  color: var(--palettes-gray-80);

  cursor: not-allowed;
}

.flatpickr-day.week.selected {
  border-radius: 0;

  box-shadow: -5px 0 0 var(--palettes-blue-100),
    5px 0 0 var(--palettes-blue-100);
}

.rangeMode .flatpickr-day {
  margin-top: 1px;
}

.flatpickr-weekwrapper {
  display: inline-block;
  float: left;
}

.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
}

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;

  line-height: 20px;
}

.flatpickr-weekwrapper span.flatpickr-day,
.flatpickr-weekwrapper span.flatpickr-day:hover {
  display: block;
  width: 100%;
  max-width: none;

  color: rgba(64, 72, 72, 0.3);

  background: 0 0;
  cursor: default;
}

.flatpickr-weekwrapper span.flatpickr-day:hover {
  border: 0;
}

.flatpickr-innerContainer {
  display: block;
  display: flex;
  box-sizing: border-box;
  overflow: hidden;
}

.flatpickr-rContainer {
  display: inline-block;
  box-sizing: border-box;
  padding: 0;
}

.flatpickr-time,
.flatpickr-time input {
  box-sizing: border-box;

  text-align: center;
}

.flatpickr-time {
  display: flex;
  height: 0;
  max-height: 40px;
  overflow: hidden;

  line-height: 40px;

  outline: 0;
}

.flatpickr-time:after {
  display: table;
  clear: both;

  content: '';
}

.flatpickr-time .numInputWrapper {
  flex: 1;
  float: left;
  width: 40%;
  height: 40px;
}

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: $grey3;
}

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: $grey3;
}

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}

.flatpickr-time input {
  position: relative;

  height: inherit;
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 0;

  color: $grey3;
  font-size: 14px;
  line-height: inherit;

  background: 0 0;
  box-shadow: none;
  cursor: pointer;
}

.flatpickr-time input.flatpickr-hour {
  font-weight: 700;
}

.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
  font-weight: 400;
}

.flatpickr-time input:focus {
  border: 0;

  outline: 0;
}

.flatpickr-time .flatpickr-am-pm,
.flatpickr-time .flatpickr-time-separator {
  display: inline-block;
  align-self: center;
  float: left;
  width: 2%;
  height: inherit;

  color: $grey3;
  font-weight: 700;
  line-height: inherit;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flatpickr-time .flatpickr-am-pm {
  width: 18%;

  font-weight: 400;
  text-align: center;

  outline: 0;
  cursor: pointer;
}

.flatpickr-time .flatpickr-am-pm:focus,
.flatpickr-time .flatpickr-am-pm:hover {
  background: #f3f3f3;
}

.flatpickr-input[readonly] {
  cursor: pointer;
}

.flatpickr-calendar {
  width: 288px;
  padding: 24px;
}

.dayContainer {
  padding: 0;
  border-right: 0;
  gap: 11px;
}

span.flatpickr-day,
span.flatpickr-day.nextMonthDay,
span.flatpickr-day.prevMonthDay {
  max-width: none;
  border-color: transparent;
}

span.flatpickr-day.nextMonthDay:nth-child(n + 8),
span.flatpickr-day.prevMonthDay:nth-child(n + 8),
span.flatpickr-day:nth-child(n + 8) {
  border: transparent;
}

span.flatpickr-day.nextMonthDay:nth-child(7n-6),
span.flatpickr-day.prevMonthDay:nth-child(7n-6),
span.flatpickr-day:nth-child(7n-6) {
  border-left: 0;
}

span.flatpickr-day.nextMonthDay:nth-child(n + 36),
span.flatpickr-day.prevMonthDay:nth-child(n + 36),
span.flatpickr-day:nth-child(n + 36) {
  border-bottom: 0;
}

span.flatpickr-day.nextMonthDay:nth-child(-n + 7),
span.flatpickr-day.prevMonthDay:nth-child(-n + 7),
span.flatpickr-day:nth-child(-n + 7) {
  margin-top: 0;
}

span.flatpickr-day.nextMonthDay.today:not(.selected),
span.flatpickr-day.prevMonthDay.today:not(.selected),
span.flatpickr-day.today:not(.selected) {
  border: 1px solid #B1B0B2;
}

span.flatpickr-day.endRange,
span.flatpickr-day.nextMonthDay.endRange,
span.flatpickr-day.nextMonthDay.startRange,
span.flatpickr-day.prevMonthDay.endRange,
span.flatpickr-day.prevMonthDay.startRange,
span.flatpickr-day.startRange {
  border-color: var(--palettes-blue-100);
}

span.flatpickr-day.nextMonthDay.selected,
span.flatpickr-day.nextMonthDay.today,
span.flatpickr-day.prevMonthDay.selected,
span.flatpickr-day.prevMonthDay.today,
span.flatpickr-day.selected,
span.flatpickr-day.today {
  z-index: 2;
}

span.flatpickr-day.prevMonthDay.flatpickr-disabled,
span.flatpickr-day.nextMonthDay.flatpickr-disabled {
  color: $black2;
  cursor: not-allowed;
}

.flatpickr-weekwrapper .flatpickr-weeks {
  box-shadow: none;
}

.flatpickr-weekwrapper span.flatpickr-day {
  margin: -1px 0 0 -1px;
  border: 0;
}

.hasWeeks .flatpickr-days {
  border-right: 0;
}

.flatpickr-time {
  display: none;
}

.form-control.input,
.flatpickr-input {
  width: 100%;
  padding: 18px 16px 6px;
  font-size: 14px;
  line-height: 18px;
  font-family: Roboto, sans-serif;
  letter-spacing: 0.25px;
  background: transparent;

  border: none;
  border-radius: 0;
  white-space: nowrap;
  outline: 0;
}

.form-control.input::-webkit-calendar-picker-indicator,
.form-control.input::-webkit-inner-spin-button,
.flatpickr-input::-webkit-calendar-picker-indicator,
.flatpickr-input::-webkit-inner-spin-button {
  display: none;

  appearance: none;
}

.form-control.input:disabled,
.flatpickr-input:disabled {
  background: transparent;
  opacity: 1;
}

/* Change placeholder color */
.form-control.input::placeholder,
.flatpickr-input::placeholder {
  color: #a9a5a9;
}

/* Hide Placeholder on focus */
/*.form-control.input:focus::placeholder
/*.flatpickr-input:focus::placeholder {*/
/*  opacity: 0;*/
/*}*/

.flatpickr-wrapper {
  position: relative;
  width: 100%;
}

// Иконка календаря
// .flatpickr-wrapper:after {
//   position: absolute;
//   top: 8px;
//   right: 9px;
//   z-index: -1;

//   display: block;
//   width: 32px;
//   height: 32px;

//   background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTI3IDJoNHYyOUgxVjJoNHY0aDVWMmgxMnY0aDVWMnpNMyA5djIwaDI2VjlIM3ptMy05aDN2NUg2VjB6TTUgMTFoNHY0SDV2LTR6bTYgMGg0djRoLTR2LTR6bTYgMGg0djRoLTR2LTR6bTYgMGg0djRoLTR2LTR6TTUgMTdoNHY0SDV2LTR6bTYgMGg0djRoLTR2LTR6bTYgMGg0djRoLTR2LTR6bTYgMGg0djRoLTR2LTR6TTUgMjNoNHY0SDV2LTR6bTYgMGg0djRoLTR2LTR6bTYgMGg0djRoLTR2LTR6bTYtMjNoM3Y1aC0zVjB6IiBmaWxsPSIjM0IzOTNGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=);
//   opacity: 0.7;

//   transition: 0.2s;

//   content: '';
// }

.show-placeholder > input.flatpickr-mobile::before {
  color: #acacac;

  content: attr(placeholder);
}

.flatpickr-month {
  height: 31px;
  margin-bottom: 24px;

  .flatpickr-current-month {
    position: relative;
    left: 0;
    width: 100%;
    padding: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
  }

  .flatpickr-monthDropdown-months,
  .numInput.cur-year {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    border: 1px solid var(--palettes-gray-40);
    padding: 4px 8px;
    border-radius: 4px;

    &:hover {
      border: 1px solid var(--palettes-blue-40);
    }

    &:focus {
      border: 1px solid var(--palettes-blue-100);
    }
  }

  .numInput.cur-year {
    width: 56px;
    transition: 0.2s;
    color: var(--text-base);

    &:hover {
      border: 1px solid var(--palettes-blue-40);
    }

    &:focus {
      border: 1px solid var(--palettes-blue-100);
    }
  }

  .flatpickr-monthDropdown-months {
    padding: 4px 16px 4px 8px;
  }

  .numInputWrapper {
    margin-left: 8px;
    margin-right: 8px;

    .arrowUp,
    .arrowDown {
      display: none;
    }
  }
}
