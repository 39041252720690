.select {
  &__menu {
    z-index: 2 !important;

    border-radius: 8px;
    overflow: hidden;

    color: var(--palettes-gray-190);
    font-size: 16px;
    line-height: 22px;
  }

  &__input {
    position: relative;

    font-size: 14px;
  }

  &__value-container {
    min-height: 44px !important;
    padding: 3px 12px 3px 15px !important;
  }

  &__placeholder {
    font-size: 14px;
    line-height: 18px;
  }

  &__control {
    width: 100%;
    min-height: 44px !important;
    padding: 0 !important;
    border: none !important;
    border-radius: 8px !important;

    letter-spacing: 0.25px;

    box-shadow: inset 0 0 0 2px var(--palettes-gray-40);

    &:hover {
      box-shadow: inset 0 0 0 2px var(--palettes-blue-40);
      cursor: text;
    }

    &:focus {
      box-shadow: inset 0 0 0 2px var(--palettes-blue-100);
    }

    &--is-focused {
      border: none !important;

      box-shadow: inset 0 0 0 2px var(--palettes-blue-100) !important;
    }

    &--is-disabled {
      background-color: var(--palettes-gray-20) !important;
    }

    transition: 0.2s;
  }

  &__multi-value {
    height: 22px;
    margin: 4px !important;
    border-radius: 4px !important;

    color: var(--text-base);

    background-color: var(--palettes-blue-20) !important;
  }

  &__multi-value__remove {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    padding: 0;
    border-radius: 0 4px 4px 0 !important;

    cursor: pointer;

    transition: 0.2s;

    &:hover {
      color: var(--text-base) !important;

      background-color: var(--palettes-blue-40) !important;
    }

    &:active {
      color: white !important;

      background-color: var(--palettes-blue-40) !important;
    }

    svg {
      width: 16px;
      height: 16px;
    }
    //&::before { // это полоса перед крестиком в мульти селекте, уже три раза Фигман то рисует её то убирает, оставим коммент, чтоб в очередной раз не тратить время на реализацию
    //  position: absolute;
    //
    //  display: inline-block;
    //  width: 1px;
    //  height: 17px;
    //  margin-right: 30px;
    //
    //  background: white;
    //
    //  content: '';
    //}
  }

  &__multi-value__label {
    padding: 4px 4px 4px 6px !important;

    color: var(--text-base) !important;
    font-size: 12px !important;
    line-height: 14px;
    letter-spacing: 0.4px;
  }

  &__option--is-focused {
    //background-color: var(--palettes-blue-10) !important; на всякий случай не удаляю

    &:active {
      background-color: var(--palettes-blue-40) !important;
    }
  }
}

.select-container {
  &.withError {
    .main-select__control,
    .select__control {
      box-shadow: inset 0 0 0 2px var(--palettes-red-100);
    }

    .main-select__placeholder,
    .select__control {
      color: var(--palettes-red-100);
    }
  }
}
