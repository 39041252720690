// Получаем значение с единицами измерения если значение число
@function get-with-unit($value, $unit: px) {
  @if (type-of($value) == 'number') {
    @return #{$value}#{$unit};
  } @else {
    @return $value;
  }
}

// где свойство(property) это один из вариантов
$property: (
  // Внешние отступы блока
  m: 'margin',
  // внутренние оступы блока
  p: 'padding',
) !default;

// Где сторона(sides) это один из вариантов:
$sides: (
  // t - для классов которые устанавливают margin-block-start или padding-block-start
  t: -block-start,
  // b - для классов которые устанавливают margin-block-end или padding-block-end
  b: -block-end,
  // s - (start) для классов которые устанавливают margin-inline-start или padding-inline-start
  s: -inline-start,
  // e - (end) для классов которые устанавливают margin-inline-end или padding-inline-end
  e: -inline-end,
  // x - для классов которые устанавливают margin-inline
  x: -inline,
  // y - для классов которые устанавливают margin-block
  y: -block,
  // blank - для классов которые устанавливают со всех сторон
  '': "",
) !default;

// Формируем классы для управления отступами на основе схемы
// https://www.figma.com/file/wECDRuIqmlBltXJTE9bOEn/_PS%3A-library?node-id=3538%3A19241
// _PS: library -> Spacing
@mixin make-spacers-classes($breakpoints: $grid-breakpoints) {
  @each $propertyKey, $propertyValue in $property {
    @each $sidesKey, $sidesValue in $sides {
      @each $spacersKey, $spacersValue in $spacers {
        .#{$propertyKey}#{$sidesKey}-#{$spacersKey} {
          #{$propertyValue}#{$sidesValue}: get-with-unit($spacersValue)!important;
        }

        @each $breakpoint in map-keys($breakpoints) {
          $infix: breakpoint-infix($breakpoint, $breakpoints);

          @include media-breakpoint-up($breakpoint, $breakpoints) {
            .#{$propertyKey}#{$sidesKey}-#{$infix}-#{$spacersKey} {
              #{$propertyValue}#{$sidesValue}: get-with-unit($spacersValue)!important;
            }
          }
        }
      }
    }
  }
}
