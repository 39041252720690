.toastContainer {
  bottom: 0;

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &:global(.Toastify__toast-container--top-center) {
    bottom: auto;
  }

  :global(.Toastify__toast) {
    border-radius: 14px;
  }

  :global(.Toastify__toast-wide) {
    width: 100%;
  }
}
