@import "react-toastify/dist/ReactToastify.css";

:root {

  --secondary: #000000;
  --text-base: #000000;
  --text-primary: #000000;
  --text-secondary: #ffffff;
  --text-tertiary: #89888C;

  --aliases-success: var(--palettes-green-100);
  --aliases-warning: var(--palettes-yellow-100);
  --aliases-attention: #FF7033;
  --aliases-error: var(--palettes-red-100);
  --aliases-link: var(--palettes-blue-100);

  --palettes-brand-money: #0e920e;

  --palettes-additional-format-draft-label: #a95e1c;
  --palettes-additional-format-draft-bg: #ffefd6;

  --palettes-gray-5: #F4F4F6;
  --palettes-gray-10: #e9ebf0;
  --palettes-gray-20: #e2e4ea;
  --palettes-gray-40: #cbced9;
  --palettes-gray-60: #b4b8c7;
  --palettes-gray-80: #9da2b5;
  --palettes-gray-90: #4F4D52;
  --palettes-gray-100: #868CA2;
  --palettes-gray-110: #f5f5f51a;

  --palettes-gray-120: #70778F;
  --palettes-gray-140: #5A617C;
  --palettes-gray-160: #444C69;
  --palettes-gray-180: #2F3655;
  --palettes-gray-190: #13192E;

  --palettes-green-5: #f2f9f6;
  --palettes-green-10: #e6f3ed;
  --palettes-green-20: #cce7da;
  --palettes-green-30: #b3dbc8;
  --palettes-green-40: #99cfb5;
  --palettes-green-50: #80c3a3;
  --palettes-green-60: #66b791;
  --palettes-green-70: #4dab7e;
  --palettes-green-80: #339f6c;
  --palettes-green-90: #1a935a;
  --palettes-green-100: #008747;

  --palettes-orange-10: #FFF6F1;
  --palettes-orange-20: #ffdcca;
  --palettes-orange-40: #ffc4a6;
  --palettes-orange-60: #FFAA80;
  --palettes-orange-80: #FF884C;
  --palettes-orange-100: #FF5500;

  --palettes-orange-120: #CD2D00;
  --palettes-orange-140: #9A0C00;
  --palettes-orange-160: #680000;
  --palettes-orange-180: #350000;
  --palettes-orange-190: #120000;

  --palettes-yellow-5: #fffbf5;
  --palettes-yellow-10: #fff7eb;
  --palettes-yellow-20: #ffefd6;
  --palettes-yellow-30: #ffe6c2;
  --palettes-yellow-40: #ffdead;
  --palettes-yellow-50: #ffd699;
  --palettes-yellow-60: #ffce85;
  --palettes-yellow-70: #ffc670;
  --palettes-yellow-80: #ffbd5c;
  --palettes-yellow-90: #ffb547;
  --palettes-yellow-100: #ffad33;

  --palettes-blue-10: #f4f4fc;
  --palettes-blue-20: #EAEAF9;
  --palettes-blue-40: #c3c3ee;
  --palettes-blue-60: #9d9de3;
  --palettes-blue-80: #6262d9;
  --palettes-blue-100: #3d3dc7;

  --palettes-blue-120: #262FAB;
  --palettes-blue-140: #0A1E78;
  --palettes-blue-160: #04154C;
  --palettes-blue-180: #010F35;
  --palettes-blue-190: #010B28;

  --palettes-red-5: #fef2f5;
  --palettes-red-10: #fee6ea;
  --palettes-red-20: #fcccd6;
  --palettes-red-30: #fbb3c1;
  --palettes-red-40: #f999ac;
  --palettes-red-50: #f88098;
  --palettes-red-60: #f76683;
  --palettes-red-70: #f54d6f;
  --palettes-red-80: #f4335a;
  --palettes-red-90: #f31a45;
  --palettes-red-100: #f10030;

  --palettes-white-100: #ffffff;

  --palettes-tooltip-background: #3B393F;

  // тосты

  --toastify-color-light: var(--text-secondary);
  --toastify-color-dark: var(--text-base);
  --toastify-color-info: var(--palettes-blue-40);
  --toastify-color-success: var(--palettes-green-40);
  --toastify-color-warning: var(--palettes-yellow-40);
  --toastify-color-error: var(--palettes-red-40);
  --toastify-z-index: var(--topmost);

  // размеры

  --default-header-height: 60px;
  --mobile-header-height: 44px;
  --default-centered-content-max-width: 640px;
  --default-centered-content-min-width: 640px; // пока поживет так до момента пока не поймем, как выглядит на мобилках

  // z-index

  --below-all: -1000; // ниже только преисподняя.

  --behind-all: -1; // прячется за всем.

  --content: 1; // обычное содержание страницы

  --dropdown-overlay: 2; // для оверлея дропдауна

  --header: 3; // header

  --above-content: 10; // что-то чуть выше контента (какой-то дропдаун или чет такое)

  --overlay: 100; // для оверлея модалки

  --overlay-content: 200; // контент модалки

  --above-overlay-content: 300; // на случай если надо что-то поверх модалки

  --topmost: 1000; // выше только аллах
}

[data-theme='dark'] {
  --secondary: #ccc;

  --text-base: white;
  --text-primary: white;
  --text-secondary: #ffffff;

  --accents-0: #212529;
  --palettes-white-100: #343a40;
  --accents-2: #495057;
  --palettes-gray-10: #868e96;
  --accents-4: #adb5bd;
  --accents-5: #ced4da;
  --accents-6: #dee2e6;
  --accents-7: #e9ecef;
  --accents-8: #f1f3f5;
  --accents-9: #f8f9fa;
}
