@import "root";
@import "variables";
@import "fonts";

@import "mixins/breakpoints";
@import "mixins/grid";
@import "mixins/spacers";

@import "components/normalize";
@import "components/grid";
@import "components/spacers";
@import "components/select";
@import "components/carousel";
@import "components/datepicker";
@import "components/modal";

.shadowLevel0 {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.24), 2px 4px 8px rgba(0, 0, 0, 0.04);
}

.shadowLevel1 {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
}

.shadowLevel2 {
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.15), 0 8px 16px rgba(0, 0, 0, 0.04);
}

.visually-hidden {
  position: absolute;

  width: 1px;
  height: 1px;
  overflow: hidden;

  white-space: nowrap;

  clip: rect(0 0 0 0);
  clip-path: inset(50%);
}

a {
  color: var(--aliases-link);

  cursor: pointer;
}

.MuiSlider-thumb {
  width: 18px !important;
  height: 18px !important;
}

.Toastify__toast-container {
  height: fit-content;
}
