@charset "UTF-8";

$slick-font-path: "../../public/fonts/" !default;

//noinspection CssInvalidFunction
@function slick-font-url($url) {
  @if function-exists(font-url) {
    @return font-url($url);
  }
  @else {
    @return url($slick-font-path + $url);
  }
}

@font-face {
  font-weight: normal;
  font-family: "slick";
  font-style: normal;
  src: slick-font-url("slick.eot");
  src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
}

@font-face {
  font-weight: normal;
  font-family: "ALS Sirius";
  font-style: normal;
  src: slick-font-url("../../public/fonts/ALSSirius-Regular.woff");
}

@font-face {
  font-weight: normal;
  font-family: "ALS Sirius Medium";
  font-style: normal;
  src: slick-font-url("../../public/fonts/ALSSirius-Medium.woff");
}

@font-face {
  font-weight: normal;
  font-family: "ALS Sirius Bold";
  font-style: normal;
  src: slick-font-url("../../public/fonts/ALSSirius-Bold.woff");
}
