@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-family: "slick" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

/* Arrows */

.slick-prev,
.slick-next {
  position: absolute;
  top: 50%;

  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  border: none;

  color: transparent;
  font-size: 0;
  line-height: 0;

  background: transparent;
  outline: none;
  transform: translate(0, -50%);
  cursor: pointer;
  &:hover, &:focus {
    color: transparent;

    background: transparent;
    outline: none;
    &:before {
      opacity: $slick-opacity-on-hover;
    }
  }
  &.slick-disabled:before {
    opacity: $slick-opacity-not-active;
  }

  //noinspection Stylelint
  &:before {
    color: $slick-arrow-color;
    font-size: 20px;
    font-family: $slick-font-family;
    line-height: 1;

    opacity: $slick-opacity-default;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

//noinspection Stylelint
.slick-prev {
  left: -25px;
  [dir="rtl"] & {
    right: -25px;
    left: auto;
  }
  //noinspection Stylelint
  &:before {
    content: $slick-prev-character;
    [dir="rtl"] & {
      content: $slick-next-character;
    }
  }
}

//noinspection Stylelint
.slick-next {
  right: -25px;
  [dir="rtl"] & {
    right: auto;
    left: -25px;
  }
  //noinspection Stylelint
  &:before {
    content: $slick-next-character;
    [dir="rtl"] & {
      content: $slick-prev-character;
    }
  }
}

/* Dots */

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;

  display: block;
  width: 100%;
  margin: 0;
  padding: 0;

  text-align: center;

  list-style: none;
  li {
    position: relative;

    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
    button {
      display: block;
      width: 20px;
      height: 20px;
      padding: 5px;
      border: 0;

      color: transparent;
      font-size: 0;
      line-height: 0;

      background: transparent;
      outline: none;
      cursor: pointer;
      &:hover, &:focus {
        outline: none;
        &:before {
          opacity: $slick-opacity-on-hover;
        }
      }
      //noinspection Stylelint
      &:before {
        position: absolute;
        top: 0;
        left: 0;

        width: 20px;
        height: 20px;

        color: $slick-dot-color;
        font-size: $slick-dot-size;
        font-family: $slick-font-family;
        line-height: 20px;
        text-align: center;

        opacity: $slick-opacity-not-active;

        content: $slick-dot-character;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
    &.slick-active button:before {
      color: $slick-dot-color-active;

      opacity: $slick-opacity-default;
    }
  }
}
